import React, { useReducer, useEffect, useMemo, useRef } from 'react';
import { Platform, AsyncStorage } from 'react-native';
import { Appearance, AppearanceProvider, ColorSchemeName } from 'react-native-appearance';
import { ThemeColorScheme } from '../types';

export { Appearance, AppearanceProvider };

const IS_WEB = Platform.OS === 'web';
export const COLOR_SCHEME_KEY = 'color-scheme' as const;
export const COLOR_SCHEME_DATA_ATTR = 'data-color-scheme' as const;

// Here we assume `body` already has the `defaultScheme` class set.
// For Gatsby, is is done in `../frameworks/gatsby/gatsbySSR.tsx`.
// Same color-scheme-resolving logic as:
// https://github.com/expo/react-native-appearance/blob/3e211b3e80180275d49487c0f36c5387a53ca888/src/NativeAppearance.web.tsx
export const WebColorSchemeInit = ({ defaultScheme }: { defaultScheme: ThemeColorScheme }) => (
  <script
    key={`${COLOR_SCHEME_KEY}-no-flash`}
    dangerouslySetInnerHTML={{
      __html: `(function() { try {
        var defaultScheme = ${JSON.stringify(defaultScheme)};
        var systemScheme = defaultScheme;
        var query = window.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)') : null;

        if (query && query.addListener && query.removeListener && typeof query.matches === 'boolean') {
          systemScheme = query.matches ? 'dark' : 'light';
        }

        var appScheme = localStorage.getItem('${COLOR_SCHEME_KEY}');
        var colorScheme = appScheme || systemScheme;

        if (colorScheme !== defaultScheme) {
          // IE11 doesn't support replace() :(
          document.body.classList.remove('${COLOR_SCHEME_KEY}-' + defaultScheme);
          document.body.classList.add('${COLOR_SCHEME_KEY}-' + colorScheme);
        }
      } catch (e) {} })();`,
    }}
  />
);

interface ColorSchemeState {
  systemScheme: ThemeColorScheme | null;
  appScheme: ThemeColorScheme | null;
}

const initialState = {
  systemScheme: null,
  appScheme: null,
};

function reducer(state: ColorSchemeState, newState: Partial<ColorSchemeState>) {
  return {
    ...state,
    ...newState,
  };
}

export function useColorSchemeState(defaultScheme: ThemeColorScheme) {
  const [{ systemScheme, appScheme }, setState] = useReducer(reducer, initialState);
  const colorScheme = appScheme || systemScheme;
  const value = useMemo(
    () => ({
      colorScheme: colorScheme ?? defaultScheme,
      // `loading` means we're still trying to resolve the system and app schemes, but
      // `colorScheme` is still available from `defaultScheme``.
      loading: !colorScheme,
      setColorScheme: (appScheme: ThemeColorScheme | null) => setState({ appScheme }),
    }),
    [colorScheme, defaultScheme]
  );
  const hasBodyClass = useRef<boolean>(true);

  /**
   * 1st effect:
   * Run once after first render to load color scheme state.
   */
  useEffect(() => {
    const systemScheme = resolveSystemScheme(Appearance.getColorScheme(), defaultScheme);

    if (IS_WEB) {
      const appScheme = localStorage.getItem(COLOR_SCHEME_KEY) as ThemeColorScheme;
      setState({ systemScheme, appScheme });
    } else {
      (async () => {
        const appScheme = (await AsyncStorage.getItem(COLOR_SCHEME_KEY)) as ThemeColorScheme;
        setState({ systemScheme, appScheme });
      })();
    }

    const subscription = Appearance.addChangeListener(({ colorScheme }) =>
      setState({ systemScheme: resolveSystemScheme(colorScheme, defaultScheme) })
    );

    return () => subscription.remove();
  }, [defaultScheme]);

  /**
   * 2nd effect:
   * Run every time `appScheme` changes to set it in local storage,
   * but only after color scheme state has been loaded from the 1st effect.
   */
  useEffect(() => {
    if (!systemScheme) return;

    const storage = IS_WEB ? localStorage : AsyncStorage;

    if (appScheme) {
      storage.setItem(COLOR_SCHEME_KEY, appScheme);
    } else {
      storage.removeItem(COLOR_SCHEME_KEY);
    }
  }, [systemScheme, appScheme]);

  /**
   * 3rd effect (web only):
   * Runs once after the 1st effect to remove the body class.
   */
  useEffect(() => {
    // * This logic has to match WebColorSchemeInit
    if (IS_WEB && colorScheme && hasBodyClass.current) {
      document.body.classList.remove(`${COLOR_SCHEME_KEY}-${colorScheme}`);
      hasBodyClass.current = false;
    }
  }, [colorScheme]);

  return value;
}

/**
 * Helpers.
 */

function resolveSystemScheme(scheme: ColorSchemeName, defaultScheme: ThemeColorScheme) {
  return scheme === 'no-preference' ? defaultScheme : scheme;
}
