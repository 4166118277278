import React, { useRef, useState, useMemo, useCallback } from 'react';
import { Platform, View, LayoutRectangle, StyleSheet, LayoutChangeEvent } from 'react-native';
import { ThemeShape } from '../types';
import { AppearanceProvider, useColorSchemeState } from './ColorScheme';
import { ColorSchemeContext } from './ColorSchemeContext';
import { CSSProvider } from './CSS';
import { SafeAreaProvider } from '../components/extra';
import { StatusBar } from '../components/StatusBar';
import { ThemeContext } from './ThemeContext';

const IS_WEB = Platform.OS === 'web';
const IS_ANDROID = Platform.OS === 'android';
/**
 * If `initialSafeAreaInsets` are not set, Then SafeAreaProvider will wait until the insets
 * are measured to render => breaking SSR for web.
 */
const initialInsets = { left: 0, top: 0, right: 0, bottom: 0 };

/**
 * ThemeProvider
 */
export const UIProvider = ({
  theme,
  children,
  fallback,
}: {
  theme: ThemeShape;
  children?: React.ReactNode;
  fallback?: React.ReactElement;
}) => {
  const colorSchemeState = useColorSchemeState(theme.defaultColorScheme);

  if (fallback && colorSchemeState.loading) {
    return fallback;
  }

  let el = (
    <ThemeContext.Provider value={theme}>
      <AppearanceProvider>
        <ColorSchemeContext.Provider value={colorSchemeState}>
          <SafeAreaProvider initialSafeAreaInsets={IS_WEB ? initialInsets : undefined}>
            {IS_WEB ? null : (
              <StatusBar style={colorSchemeState.colorScheme === 'light' ? 'dark' : 'light'} />
            )}
            {IS_ANDROID ? <AndroidRootView>{children}</AndroidRootView> : children}
          </SafeAreaProvider>
        </ColorSchemeContext.Provider>
      </AppearanceProvider>
    </ThemeContext.Provider>
  );

  if (IS_WEB) {
    el = <CSSProvider globalCSS={theme.colorSchemeCSS}>{el}</CSSProvider>;
  }

  return el;
};

/**
 * Fix Android keyboard handling.
 *
 * By default, `softwareKeyboardLayoutMode` is "resize". However, we wwant KeyboardAvoidingView to
 * fill the screen without resizing while creating a bottom padding with the keyboard displayed
 * on top (like on iOS).
 *
 * "pan" works except for when there's an input at the bottom of the screen, which when focused
 * causes the whole screen to pan up (instead of displaying the keyboard over the screen).
 *
 * Our solution is to use an `absoluteFill` view, which measures the full dimensions of the screen
 * (including the notch) before any keyboard is shown. The container view uses these dimensions to
 * ensure the app fills up the whole screen, so even if the keyboard is shown, it won't resize
 * the screen.
 *
 * For now, this assumes the orientation is locked (i.e. "portrait"). Otherwise, if the orientation
 * changes when an input is focused, the rotated screen will re-layout with smaller dimensions
 * (as the keyboard stays shown) while we need the full dimensions so the container view can fill up
 * the whole screen.
 *
 * https://github.com/facebook/react-native/issues/23693
 * https://github.com/expo/expo/issues/7815
 */
const AndroidRootView = React.memo(({ children }) => {
  const [layout, setLayout] = useState<LayoutRectangle | null>(null);
  const layoutRef = useRef<LayoutRectangle | null>(null);

  const onLayout = useCallback((e: LayoutChangeEvent) => {
    const { layout: newLayout } = e.nativeEvent;
    const layout = layoutRef.current;

    if (
      !layout ||
      // Ensure the tallest height is always used (which should cover the whole screen instead of
      // just the section resized by the keyboard).
      (newLayout.width === layout.width && newLayout.height > layout.height)
    ) {
      layoutRef.current = newLayout;
      setLayout(layoutRef.current);
    }
  }, []);

  const style = useMemo(
    () =>
      layout && {
        width: layout.width,
        height: layout.height,
      },
    [layout]
  );

  return (
    <>
      <View onLayout={onLayout} style={StyleSheet.absoluteFill} />
      <View style={style}>{children}</View>
    </>
  );
});
